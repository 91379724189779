.checkoutSection {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .tabsContainer {
        margin-top: 23px;

        button {
            border-radius: 0;
            border-bottom: 0;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }

        :global .nav-item {
            flex: none;
            outline: 0;
            @media (max-width: 992px) {
                width: 100%;
            }

            .nav-link {
                color: #949494;
                background-color: #f5f5f5;
                border: 0 !important;
                font-weight: bold;
                padding: 0.8rem 1.4rem;

                &.active {
                    color: var(--primary-color);
                    background-color: #e9e9e9;
                    border: 0;
                }
            }
        }
    }

    .totalWrapper {
        background-color: #e2e2e2;
        padding: 25px;

        @media (min-width: 992px) {
            margin-top: 24px;
        }
    }

    .buttonRow {
        background-color: #f7f7f7;
    }

    .continueShoppingButton {
        display: block;
        border: none;
        padding: 15px 30px;
        background-color: #9d0000;
        font-weight: 500;
    }

    .priceWrapper {
        padding-bottom: 10px;
    }

    .transportWrapper {
        padding-top: 10px;
    }

    .transportWrapper {
        border-top: 1px solid #c7c7c7;
    }

    .summaryText,
    .summaryTotal {
        font-size: 14px;
    }

    .summaryTotal {
        font-size: 18px;
        font-weight: 500;
    }

    .finalizeButton,
    .backToCart {
        display: block;
        margin-top: 20px;
        border: none;
        padding: 13px 25px;
        font-weight: 500;
        font-size: 1rem;
        cursor: pointer;
    }

    .backToCart {
        background-color: #9c9c9c;

        &:active {
            background-color: #9c9c9c;
        }
    }

    .finalizeButton {
        background-color: #008c00;

        &:active {
            background-color: #008c00;
        }
    }

    .inputWrapper {
        background-color: #e9e9e9;
    }

    input {
        border: 1px solid #cccccc;
        border-radius: 0;
        background-color: #fff;
    }

    .subText {
        font-size: 13px;
        color: #8b8b8b;
    }

    .link {
        color: #9d0000;
    }
}

.streetSuggestions {
    position: absolute;
    background-color: white;
    padding-left: 15px;
    z-index: 10;
    overflow: auto;
    max-height: 15rem;
    list-style: none;
    border: 1px solid rgb(230, 230, 230);
    width: 100%;
    top: 100%;

    .suggestion {
        cursor: pointer;
        border-bottom: 1px solid rgb(230, 230, 230);
        padding: 10px 0;
        margin-bottom: 2px;
    }
}

.errorCard {
    background-color: rgba(231, 76, 60, 0.4);
    font-weight: 500;
    color: #fff;
    padding: 12px;

    @media (max-width: 576px) {
        font-size: 14px;
    }

    svg {
        font-size: 30px;
        color: #e74c3c;
        margin-right: 15px;
    }
}
